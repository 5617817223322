import { en, nl, fr, de } from './messages';
import VueI18n from 'vue-i18n';

const messages = {
  en,
  nl,
  fr,
  de,
}

let instance;

export const initiliazeTranslations = () =>  {
  return instance = new VueI18n({
    locale: navigator.language,
    fallbackLocale: 'en',
    missing: (locale, key) => {
      // handle translation missing
      // eslint-disable-next-line no-console
      console.log( locale, key);
    },
    messages
  })
}

export const getI18n = () => {
  return instance;
};